import Contact from "./services/Contact.js";
import Map from "./services/Map.js";
import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";

document.addEventListener(
	"DOMContentLoaded",
	() => {
		const overlay = new Overlay();
		/* eslint-disable */
		const reviewsSlider = new Slider('[data-slider="reviews"]');
		const findUsMap = new Map('[data-map="find-us"]');
		const formContact = new Contact('[data-form="contact"]', [
			"name",
			"phone",
			"email",
			"message",
		]);
		const facilitySlider = new Slider('[data-slider="facility-images"]');
		const singleFacilityMap = new Map('[data-map="single-facility"]');
		/* eslint-enable*/

		// Toggle Mobile Menu
		(() => {
			const navOpenBtn = document.querySelector('[data-js="nav-open"]');
			const navCloseBtn = document.querySelector('[data-js="nav-close"]');
			const navMenu = document.querySelector('[data-js="nav-menu"]');

			if (!navMenu) {
				return;
			}

			if (navOpenBtn) {
				navOpenBtn.addEventListener("click", () => {
					navMenu.classList.add("active");
					overlay.show();
					overlay.addListenerOnClose(() => {
						navMenu.classList.remove("active");
					});
				});
			}

			if (navCloseBtn) {
				navCloseBtn.addEventListener("click", () => {
					navMenu.classList.remove("active");
					overlay.hide();
				});
			}
		})();

		// Toggle Mobile Subitems
		(() => {
			const navMenu = document.querySelector('[data-js="nav-menu"]');

			if (!navMenu) {
				return;
			}

			navMenu.addEventListener("click", (e) => {
				e.stopImmediatePropagation();

				const toggleMobileSubitems = e.target.closest(
					'[data-js="toggle-subitems"]'
				);

				if (!toggleMobileSubitems) {
					return;
				}

				toggleMobileSubitems.classList.toggle("active");
			});
		})();

		//Toggle Storage Tips
		(() => {
			const storageTipsTabs = document.querySelectorAll(
				'[data-js="storage-tips-tabs"]'
			);
			const tipBlocks = document.querySelectorAll(".tip-block");

			if (storageTipsTabs.length <= 0 || tipBlocks.length <= 0) {
				return;
			}

			storageTipsTabs.forEach((btn, index) => {
				btn.addEventListener("click", () => {
					// Remove 'active' class from all tabs
					storageTipsTabs.forEach((tab) => tab.classList.remove("active"));

					// Add 'active' class to the clicked tab
					btn.classList.add("active");

					// Hide all tip blocks
					tipBlocks.forEach((block) => block.classList.remove("active"));

					// Show the corresponding tip block based on the clicked tab
					tipBlocks[index].classList.add("active");
				});
			});
		})();

		// Toggle Storage FAQs
		(() => {
			const storageFaqs = document.querySelector(
				"[data-js='toggle-faq-items']"
			);

			if (!storageFaqs) {
				return;
			}

			storageFaqs.addEventListener("click", (e) => {
				if (!e.target.classList.contains("question")) {
					return;
				}

				e.target.classList.toggle("active");
			});
		})();

		// Single Facility - Switch Map / Gallery
		(() => {
			const wrapperGallery = document.querySelector(
				".wrapper-gallery[data-showing]"
			);
			const viewControlBtns = document.querySelectorAll(
				".wrapper-gallery [data-show]"
			);

			if (!wrapperGallery || viewControlBtns.length <= 0) {
				return;
			}

			wrapperGallery.addEventListener("click", (e) => {
				const viewControlBtn = e.target.closest("[data-show]");

				if (!viewControlBtn) {
					return;
				}

				wrapperGallery.setAttribute(
					"data-showing",
					viewControlBtn.getAttribute("data-show")
				);
			});
		})();

		// Single Facility - Filter Rates
		(() => {
			const facilityRatesWrapper = document.querySelector(
				".facility-rates-wrapper"
			);
			const facilityFilters = document.querySelector(".facility-filters");

			const noUnits = document.querySelector('#no_units');

			if (!facilityRatesWrapper || !facilityFilters) {
				return;
			}

			facilityFilters.addEventListener("change", () => {
				let unitShown = 0;
				const formData = new FormData(facilityFilters);
				const filterName = formData.get("facility-filter");
				facilityRatesWrapper.setAttribute("data-filter", filterName);
				[].forEach.call(document.querySelectorAll('.rates-table .unit'), (unit) => {

					if (filterName === "all" || unit.dataset.type.includes(filterName)) {
						unitShown++;
					}
					if (unitShown === 0) {
						noUnits.classList.add('active');
					} else {
						noUnits.classList.remove('active');
					}
				});
			});
		})();

		(() => {
			const ratestable = document.querySelector(".facility-rates-wrapper");

			if (!ratestable) {
				return;
			}

			function handleUnitClick(event) {
				const unit = event.currentTarget;
				const manageCell = unit.querySelector("td.manage");
				const rentButtons = manageCell.querySelector(".rent-buttons");
				const anchorTag = rentButtons.querySelector("a");

				if (anchorTag && typeof anchorTag.click === "function") {
					anchorTag.click();
				}
			}

			const ratesTable = document.querySelector(".rates-table");

			function handleResize() {
				const isSmallScreen = window.innerWidth < 768;

				const unitElements = ratesTable.querySelectorAll(".unit");

				unitElements.forEach((unit) => {
					if (isSmallScreen) {
						unit.addEventListener("click", handleUnitClick);
					} else {
						unit.removeEventListener("click", handleUnitClick);
					}
				});
			}

			handleResize();

			window.addEventListener("resize", handleResize);
		})();

		//autoscroll
		(() => {
			const elementID = window.location.hash;
			if (elementID) {
				// Use JavaScript to scroll to the "find-us" section
				const element = document.querySelector(elementID);

				if (element) {
					element.scrollIntoView({ behavior: "smooth" });
				}
			}
		})();
	},
	true
);
